.App {
  height: 100vh;
}

.whiteField  .MuiOutlinedInput-inputAdornedEnd {
  color: white !important;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  max-width: 100px
}

.whiteField  .MuiTextField-root .MuiIconButton-root {
  color: white !important;
  padding-left: 0;
  padding-right: 0;
}

.whiteField  .MuiFormLabel-root {
  color: white !important
}

.whiteField  .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.whiteField  .MuiFormControl-marginNormal {
  margin: 0px !important
}

.whiteField  .MuiInputBase-root {
  height: 50px;
}

.whiteField  .MuiInputBase-input {
  color: white !important;
}