.list {
    box-shadow: 1px 1px 3px 2px grey;
    height: 85%;
    overflow: auto;
}

/* table, th, td {
    border: 1px solid black;
}

table {
    min-width: 100%;
} */

.device-actions {
    display: flex;
    justify-content: space-around;
}.modal-body-config {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.textarea-div {
    display: flex;
    align-items: center;
}


.rw-widget-container {
    height: 80% !important;
    width: 80% !important;
}

.response-content {
    max-height: 90vh;
    overflow: auto;
}