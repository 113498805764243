.tableC {
    width: 200px;
    border-right-color: #ffffff;
    margin-top: 10px;
    font-size: 18px;
    margin-left: 20px;
    /* /* border: 1px solid #343a40; */
    border-collapse: collapse; 
    font-size: 13px;
  }

  .theadC, .tbodyC { display: block; }

  .tbodyC{
    height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }

  
  .thC, .tdC {
    padding: 5px 10px;
    text-align: left;
  }

  .thC {
    background-color: #4050b5;
    color: #fff;
    width: 100%;
    height: 35px;
    font-size: 13px;
  }

  .tdC{
    width: 100%;
  }
  
  .button:hover{
    cursor: pointer;
  }
  .trC{
    width: 100%;
  }

  .tbodyC .trC:nth-child(odd){
    background-color: #f8f9fa;
  }

  
  .tbodyC .trC:nth-child(even){
    background-color: #e9ecef;
  }